import homeBg from './home-bg.jpg';
import aboutBg from './about-bg.jpg';
import careerBg from './career-bg.jpg';
import contactBg from './contact-bg.jpg';
import erontrans from './logo/erontrans.png';
import greenyard from './logo/greenyard.png';
import nagel from './logo/nagel.png';
import etaplus from './logo/etaplus.png';

export {
  homeBg, aboutBg, careerBg, contactBg, erontrans, greenyard, nagel, etaplus,
};
