import styled from 'styled-components';
import breakpoints from '../../constants/breakpoints';
import color from '../../constants/colors';

const ContactContainer = styled.div`
  width: 100%;
  color: ${color.black};
  background: ${color.white};
  padding: 2rem;
  margin-bottom: 4rem;
  border-radius: 2rem;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  @media (min-width: ${breakpoints.md}) {
    padding: 4rem;
    margin-bottom: unset;
  }
`;

const Row = styled.div`
  display: flex;
  padding: 0.5rem 0;
  :nth-last-of-type(1) {
    display: inline-grid;
    span {
      padding-left: unset;
      padding-top: 0.5rem;
    }
  }
  p {
    font-weight: 700;
    margin: 0;
  }
  span {
    font-weight: unset;
    padding-left: 0.6rem;
  }

  a {
    color: ${color.orange};
    padding-left: 0.6rem;
    &:hover {
      color: ${color.orange};
    }
  }
`;

export { ContactContainer, Row };
