import React from 'react';
import ContentContainer from '../../common/components/ContentContainer';
import { Row } from '../styles';

const Contact = ({ data }) => (
  <ContentContainer>
    <Row>
      <p>Telefon: </p>
      <a href={`tel:${data.site.siteMetadata.contact.phone}`}>
        {data.site.siteMetadata.contact.phone}
      </a>
    </Row>
    <Row>
      <p>Email: </p>
      <a href={`mailto:${data.site.siteMetadata.contact.email}`}>
        {data.site.siteMetadata.contact.email}
      </a>
    </Row>
    <Row>
      <p>Nazwa firmy: </p>
      <span>{data.site.siteMetadata.contact.fullName}</span>
    </Row>
    <Row>
      <p>NIP: </p>
      <span>{data.site.siteMetadata.contact.nip}</span>
    </Row>
    <Row>
      <p>REGON: </p>
      <span>{data.site.siteMetadata.contact.reagon}</span>
    </Row>
    <Row>
      <p>Adres: </p>
      {data.site.siteMetadata.contact.address.map(el => (
        <span>{el.fullAddress}</span>
      ))}
    </Row>
  </ContentContainer>
);

export default Contact;
