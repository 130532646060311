import React from 'react';
import { Row, Col, Container } from 'react-grid-system';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import ContactInfo from '../../components/Contact/components/data';
import Map from '../../components/Map';
import { contactBg } from '../../assets/images';
// import ContactForm from '../../components/ContactForm/components';

const Wrapper = styled.div`
  position: relative;
  padding: 5vh 0;
  padding: 15vh 0;
  &::before {
    background-image: url(${contactBg});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    position: absolute;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: -1;
  }
`;

const Contact = () => (
  <Layout>
    <SEO title="Kontakt" />
    <Wrapper>
      <Container>
        <Row>
          <Col lg={6} md={6}>
            <ContactInfo />
          </Col>
          {/* TODO: Adjust React Hook Form, test sending email */}
          {/* <Col lg={6} md={6}>
            <ContactForm />
          </Col> */}
        </Row>
      </Container>
    </Wrapper>
    <Map />
  </Layout>
);

export default Contact;
