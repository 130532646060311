import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Map from '.';

const MapData = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            contact {
              geoLocation {
                city
                lat
                lng
                link
              }
            }
          }
        }
      }
    `}
    render={data => <Map data={data} />}
  />
);

export default MapData;
