import styled from 'styled-components';
import color from '../../../constants/colors';
import { pin } from '../../../assets/icons';

const StyledMarker = styled.div`
  width: 4rem;
  height: 4rem;
  background-image: url(${pin});
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  transform: translate(-25px, -40px);
`;

const ToolTip = styled.div`
  font-family: 'Maven Pro', sans-serif;
  height: auto;
  padding: 3rem 2rem 2rem;
  position: absolute;
  left: -6.6rem;
  bottom: 6rem;
  background: white;
  text-align: center;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  :before {
    content: ' ';
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: -10px;
    background: ${color.white};
    transform: rotate(45deg);
    left: 50px;
  }
  p {
    font-weight: 600;
    display: block;
    margin: 0;
    padding: 0;
  }
  a {
    font-size: 1.6rem;
  }
`;

const ToolTipHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Logo = styled.img`
  width: 8rem;
`;
const CloseIcon = styled.img`
  position: absolute;
  width: 1.6rem;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

export {
  StyledMarker, ToolTip, ToolTipHeader, Logo, CloseIcon,
};
