import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import Marker from '../../Marker/components';

const MapContainer = styled.div`
  height: 50vh;
  width: 100%;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
`;

const Map = ({ data }) => {
  const { geoLocation } = data.site.siteMetadata.contact;
  const center = { lat: 54.143148, lng: 17.9057338 };
  const zoom = 8;

  return (
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_API_KEY }}
        center={center}
        defaultZoom={zoom}
      >
        {geoLocation.map(el => (
          <Marker lat={el.lat} lng={el.lng} city={el.city} link={el.link} />
        ))}
      </GoogleMapReact>
    </MapContainer>
  );
};

export default Map;
