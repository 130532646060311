import React, { useState } from 'react';
import { logo, close } from '../../../assets/icons';
import {
  StyledMarker,
  ToolTip,
  ToolTipHeader,
  Logo,
  CloseIcon,
} from './styles';

const Marker = ({
  city, link,

}) => {
  const [toolTip, setToolTip] = useState(false);

  return (
    <>
      <StyledMarker onClick={() => setToolTip(true)}
      />
      {toolTip && (
        <ToolTip>
          <ToolTipHeader>
            <Logo src={logo} alt="logo" />
            <CloseIcon
              src={close}
              alt="close"
              onClick={() => setToolTip(false)}
            />
          </ToolTipHeader>
          <p>{city}</p>
          <a href={link} target="_blank" rel="noopener noreferrer">
            Dojazd
          </a>
        </ToolTip>
      )}
    </>
  );
};
export default Marker;
