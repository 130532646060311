import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Contact from '.';

const ContactData = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              email
              phone
              fullName
              nip
              reagon
              address {
                fullAddress
              }
            }
          }
        }
      }
    `}
    render={data => <Contact data={data} />}
  />
);

export default ContactData;
