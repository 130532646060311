import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../../../constants/breakpoints';
import color from '../../../../constants/colors';

const StyledContainer = styled.div`
  width: 100%;
  color: ${color.black};
  background: ${color.highTransparent};
  padding: 2rem;
  margin-bottom: 4rem;
  border-radius: 2rem;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  @media (min-width: ${breakpoints.md}) {
    padding: 4rem;
    margin-bottom: unset;
  }
`;

const ContentContainer = ({ children }) => <StyledContainer>{children}</StyledContainer>;

export default ContentContainer;
